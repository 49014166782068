import React, { ReactElement } from 'react';
import { Container } from 'react-bootstrap';
import { Layout } from '../components/common';
import ContentImageRow from '../components/common/ContentImageRow';
import BannerSection from '../components/common/contentSections/BannerSection';
import ContentHero from '../components/common/contentSections/ContentHero';
import HeadingSection from '../components/common/contentSections/HeadingSection';
import VideoSection from '../components/common/contentSections/VideoSection';
import ImageContentRow from '../components/common/ImageContentRow';
import { ContentData } from '../utils/entities';

const SecureSSOContentData: ContentData[] = [
    {
        id: 1,
        title: `Effective and fast`,
        desc: `Cancel out repeated login in credential input at every application sign in. Once verified and authenticated, customers can log into any application with StackIAM.`,
        imageUrl: `images/secure-sso-effective-fast.svg`,
    },
    {
        id: 2,
        title: `Improved security`,
        desc: `Defend against data identity breach with SSO capabilities for custom applications`,
        imageUrl: `images/secure-sso-security@3x.png`,
    },
];

const SecureSSO = (): ReactElement => (
    <Layout>
        <Container>
            <ContentHero
                heading="Secure Authentication With SSO"
                desc="Single sign on with SAML connects helps customers login into multiple applications with a single username and password."
                buttonText="Get Started"
                imageUrl="/images/secure-sso-hero.svg"
            />
            <HeadingSection
                heading="Features"
                subheading="Secure authentication with SSO"
            />
            {SecureSSOContentData.map((item) => {
                if (item.id % 2 === 0) {
                    return <ContentImageRow key={item.id} {...item} />;
                } else {
                    return <ImageContentRow key={item.id} {...item} />;
                }
            })}
            <VideoSection />
            <BannerSection />
        </Container>
    </Layout>
);

export default SecureSSO;
